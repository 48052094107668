@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url(./Poppins/Black/Poppins-Black.eot);
  src: url(./Poppins/Black/Poppins-Black.eot?#iefix) format('embedded-opentype'),
  url(./Poppins/Black/Poppins-Black.otf) format('otf'),
  url(./Poppins/Black/Poppins-Black.ttf) format('truetype'),
  url(./Poppins/Black/Poppins-Black.woff) format('woff'),
  url(./Poppins/Black/Poppins-Black.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(./Poppins/Bold/Poppins-Bold.eot);
  src: url(./Poppins/Bold/Poppins-Bold.eot?#iefix) format('embedded-opentype'),
  url(./Poppins/Bold/Poppins-Bold.otf) format('otf'),
  url(./Poppins/Bold/Poppins-Bold.ttf) format('truetype'),
  url(./Poppins/Bold/Poppins-Bold.woff) format('woff'),
  url(./Poppins/Bold/Poppins-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(./Poppins/Semi-Bold/Poppins-SemiBold.eot);
  src: url(./Poppins/Semi-Bold/Poppins-SemiBold.eot?#iefix) format('embedded-opentype'),
  url(./Poppins/Semi-Bold/Poppins-SemiBold.otf) format('otf'),
  url(./Poppins/Semi-Bold/Poppins-SemiBold.ttf) format('truetype'),
  url(./Poppins/Semi-Bold/Poppins-SemiBold.woff) format('woff'),
  url(./Poppins/Semi-Bold/Poppins-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url(./Poppins/Medium/Poppins-Medium.eot);
  src: url(./Poppins/Medium/Poppins-Medium.eot?#iefix) format('embedded-opentype'),
  url(./Poppins/Medium/Poppins-Medium.otf) format('otf'),
  url(./Poppins/Medium/Poppins-Medium.ttf) format('truetype'),
  url(./Poppins/Medium/Poppins-Medium.woff) format('woff'),
  url(./Poppins/Medium/Poppins-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(./Poppins/Regular/Poppins-Regular.eot);
  src: url(./Poppins/Regular/Poppins-Regular.eot?#iefix) format('embedded-opentype'),
  url(./Poppins/Regular/Poppins-Regular.otf) format('otf'),
  url(./Poppins/Regular/Poppins-Regular.ttf) format('truetype'),
  url(./Poppins/Regular/Poppins-Regular.woff) format('woff'),
  url(./Poppins/Regular/Poppins-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url(./Poppins/Light/Poppins-Light.eot);
  src: url(./Poppins/Light/Poppins-Light.eot?#iefix) format('embedded-opentype'),
  url(./Poppins/Light/Poppins-Light.otf) format('otf'),
  url(./Poppins/Light/Poppins-Light.ttf) format('truetype'),
  url(./Poppins/Light/Poppins-Light.woff) format('woff'),
  url(./Poppins/Light/Poppins-Light.woff2) format('woff2');
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul,
li,
th,
td,
div,
code,
button,
label,
input {
  font-family: Poppins !important;
}
