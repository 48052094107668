.Font-Headline-1{
  font-weight: 600;
  font-size: 44px;
  line-height: 64px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 56px;
  }
}

.Font-Headline-2{
  font-weight: 400;
  font-size: 36px;
  line-height: 56px;
  letter-spacing: 0;
  @media screen and (max-width: 1280px) {
    font-size: 24px;
    line-height: 36px;
  }
}

.Font-Headline-3{
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 1280px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.Font-Headline-4{
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
  @media screen and (max-width: 567px) {
    font-size: 16px;
    line-height: 28px;
  }
}

.Font-Headline-5{
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 567px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.Font-Headline-6{
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  @media screen and (max-width: 567px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.Font-Bold{
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 567px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.Font-Button{
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 567px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.Font-Body{
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 567px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.Font-Caption{
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  @media screen and (max-width: 567px) {
    font-size: 10px;
    line-height: 20px;
  }
}

.Font-Overline{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  @media screen and (max-width: 567px) {
    font-size: 8px;
    line-height: 12px;
  }
}