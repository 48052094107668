@import "./abstracts/";
@import "./vendors/";
@import "../fonts/styles/";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-image-crop/dist/ReactCrop.css';



.ReactModal__Overlay{
  z-index: 20;
}

.ReactModal__Overlay {
  background-color: rgba(14, 15, 17, 0.48)!important;
  backdrop-filter: blur(1px);
}

.gradient-title{
  background: -webkit-linear-gradient(right, #d41361, #a949d2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a:hover{
  cursor: pointer;
}

button:hover{
  cursor: pointer;
}
