.dataRow{
  &:hover{
    .details{
      display: block;
    }
  }
}

.details{
  display: none;
}



@media (min-width: 1360px){
  .xl-custom{
    display: block;
  }
}
